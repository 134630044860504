import React, { useEffect, useState } from "react";
import axios from 'axios';
import carData from "../assets/data/carData";
import { Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import { useParams, useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import ListViewItem from "../components/UI/ListViewItem";
import FindCarForm from "../components/UI/FindCarForm";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const CarDetails = () => {
  const { slug } = useParams();
  const [vehicleData, setVehicleData] = useState([]);
  const [relatedVehicleData, setRelatedVehicleData] = useState([]);
  const [vehicleServices, setVehicleServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0.00);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [formData, setFormData] = useState({
    pick_up_location: '',
    return_location: '',
    pick_up_time_date: '',
    return_time_date: '',
    person_title: '',
    first_name: '',
    last_name: '',
    vehicle_type: '',
    contact_number: '',
    email: '',
    agent: '',
    services: {},
  });

  const singleCarItem = carData.find((item) => item.carName === slug);

  useEffect(() => {
    console.log(singleCarItem);
    window.scrollTo(0, 0);
  }, [singleCarItem]);

  useEffect(() => {
    const fetchCar = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/product/details?id=${slug}`);
        console.log(response.data);
        setVehicleData(response.data);
        setName(response.data.name);
        setDescription(response.data.description);
        setImage(response.data.image_path);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching car details', error);
        setLoading(false);
      }
    };
    fetchCar();
  }, [slug]);

  useEffect(() => {
    let isMounted = true;

    axios.get(`${BASE_URL}/api/product/allProduts`)
      .then(res => {
        if (isMounted) {
          console.log(res.data);
          setRelatedVehicleData(res.data)
        }
      })
      .catch(error => {
        console.error("There was an error fetching the cars!", error);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    const fetchServices = async () => {
      let isMounted = true;
  
      try {
        const res = await axios.get(`${BASE_URL}/api/vehicle/services`);
        if (isMounted) {
          let services = res.data;
  
          // Check if the unlimited_price is more than 0 and add as a service
          if (vehicleData.unlimited_price > 0) {
            services = [
              ...services,
              {
                id: 'unlimited-kilometers',
                service_name: 'Unlimited Kilometers',
                price: parseFloat(vehicleData.unlimited_price),
                input_type: 'radio', // assuming it behaves like a radio selection
              },
            ];
          }
  
          setVehicleServices(services);
        }
      } catch (error) {
        console.error("There was an error fetching the services!", error);
      }
  
      return () => {
        isMounted = false;
      };
    };
  
    fetchServices();
  }, [vehicleData.unlimited_price]);

  useEffect(() => {
    calculateTotalPrice(); // Initial calculation
  }, [formData.services, formData.pick_up_time_date, formData.return_time_date]);

  const calculateTotalPrice = () => {
    let total = 0;

    // Calculate the date range
    const pickUpDate = new Date(formData.pick_up_time_date);
    const returnDate = new Date(formData.return_time_date);
    const daysCount = Math.ceil((returnDate - pickUpDate) / (1000 * 60 * 60 * 24));

    // Ensure daysCount is valid and greater than zero
    if (isNaN(daysCount) || daysCount <= 0) {
      setTotalPrice(0.00);
      return;
    }

    // Determine the rate based on the number of days
    let dayRate = 0;
    if (daysCount >= 1 && daysCount <= 13) {
      dayRate = parseFloat(vehicleData.one_week_per_day_rate) || 0;
    } else if (daysCount >= 14 && daysCount <= 20) {
      dayRate = parseFloat(vehicleData.two_week_per_day_rate) || 0;
    } else if (daysCount >= 21 && daysCount <= 29) {
      dayRate = parseFloat(vehicleData.three_week_per_day_rate) || 0;
    } else if (daysCount >= 30 && daysCount <= 89) {
      dayRate = parseFloat(vehicleData.one_month_per_day_rate) || 0;
    } else if (daysCount >= 90 && daysCount <= 179) {
      dayRate = parseFloat(vehicleData.three_months_per_day_rate) || 0;
    } else {
      dayRate = parseFloat(vehicleData.six_months_per_day_rate) || 0;
    }

    // Add base rate calculation to the total
    total += dayRate * daysCount;

    // Calculate price for selected services
    vehicleServices.forEach((service) => {
      const serviceSelected = formData.services[service.service_name] === 'yes';
      const quantity = parseInt(formData.services[`${service.service_name}_quantity`]) || 0;
      if (serviceSelected) {
        if (service.service_name === 'Unlimited Kilometers') {
          total += service.price * daysCount;
        } else {
          total += service.input_type === 'input' ? service.price * quantity : service.price * 1;
        }
      }
    });

    // Set totalPrice, ensuring it defaults to 0.00 if the calculation results in NaN
    setTotalPrice(isNaN(total) ? 0.00 : total);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleServiceChange = (serviceName, value) => {
    setFormData((prevState) => ({
      ...prevState,
      services: {
        ...prevState.services,
        [serviceName]: value
      }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setIsSuccess(false);
    setIsError(false);

    try {
      const response = await fetch('https://formspree.io/f/movarzqz', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setIsSuccess(true);
        setFormData({
          pick_up_location: '',
          return_location: '',
          pick_up_time_date: '',
          return_time_date: '',
          vehicle_type: '',
          contact_number: '',
          email: '',
        });
        window.alert('Email sent successfully!');
        window.location.reload();
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setIsSubmitting(false);
    }
  };


  const handleConfirmBooking = async () => {
    setIsSubmitting(true);
    setIsSuccess(false);
    setIsError(false);

    // Format rental period
    const pickUpDate = new Date(formData.pick_up_time_date);
    const returnDate = new Date(formData.return_time_date);
    const daysCount = Math.ceil((returnDate - pickUpDate) / (1000 * 60 * 60 * 24));
    const rentalPeriod = `${daysCount} ${daysCount > 1 ? 'Days' : 'Day'}`;

    try {
      // Send the email with all the booking details
      const response = await fetch('https://formspree.io/f/movarzqz', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          subject: 'New Booking (' + name + ')',
          user_email: formData.email, // User's email to identify sender
          vehicle: name,
          pickUpLocation: formData.pick_up_location,
          returnLocation: formData.return_location,
          pickUpDate: pickUpDate.toLocaleString(), // Convert dates to a human-readable format
          returnDate: returnDate.toLocaleString(),
          rentalPeriod: rentalPeriod,
          contactName: `${formData.first_name} ${formData.last_name}`,
          contactNumber: formData.contact_number,
          totalPrice: `LKR ${totalPrice.toFixed(2)}`,
          selectedServices: vehicleServices
            .filter(service => formData.services[service.service_name] === 'yes')
            .map(service => {
              const quantity = formData.services[`${service.service_name}_quantity`] || 1;
              return `${service.service_name} - ${quantity} Unit(s) - LKR ${service.price * quantity}`;
            })
            .join(',\n'), // Join services into a single string for readability
        }),
      });
  
      if (response.ok) {
        setIsSuccess(true);
        setFormData({
          pick_up_location: '',
          return_location: '',
          pick_up_time_date: '',
          return_time_date: '',
          vehicle_type: '',
          contact_number: '',
          email: '',
        });
        window.alert('Email sent successfully!');
        window.location.reload();
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setIsSubmitting(false);
      setShowConfirmation(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const SamplePrevArrow = ({ className, style, onClick }) => (
    <div
      className={className}
      style={{ ...style, display: "block", left: "40px", zIndex: 1, fontSize: "50px" }}
      onClick={onClick}
    />
  );

  const SampleNextArrow = ({ className, style, onClick }) => (
    <div
      className={className}
      style={{ ...style, display: "block", right: "40px", zIndex: 1 }}
      onClick={onClick}
    />
  );

  const sliderSettings = {
    dots: false,
    infinite: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <Helmet title={name}>
      <section>
        <Container>
          <Row>
            <Col lg="4">
              <Slider {...sliderSettings}>
                {vehicleData.product_image?.map((imagedata, index) => (
                  <div key={index}>
                    <img
                      src={`https://admin.rentacarsrilankahbs.com/${imagedata.image}`}
                      alt={`Slide ${index}`}
                      className="w-100"
                    />
                  </div>
                ))}
              </Slider>

              {/* Add the image-like section here */}
              <div className="image-like-section mt-4">
                <table className="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Per Day Rate</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vehicleData.one_week_per_day_rate || vehicleData.one_week_per_total ?
                      <tr>
                        <th>For 1 week</th>
                        <td>{vehicleData.one_week_per_day_rate ? parseFloat(vehicleData.one_week_per_day_rate).toFixed(2) : ""}/=</td>
                        <td>{vehicleData.one_week_per_total ? parseFloat(vehicleData.one_week_per_total).toFixed(2) : ""}/=</td>
                      </tr>
                      : ''
                    }

                    {vehicleData.two_week_per_day_rate || vehicleData.two_week_per_total ?
                      <tr>
                        <th>For 2 week</th>
                        <td>{vehicleData.two_week_per_day_rate ? parseFloat(vehicleData.two_week_per_day_rate).toFixed(2) : ""}/=</td>
                        <td>{vehicleData.two_week_per_total ? parseFloat(vehicleData.two_week_per_total).toFixed(2) : ""}/=</td>
                      </tr>
                      : ''
                    }

                    {vehicleData.three_week_per_day_rate || vehicleData.three_week_per_total ?
                      <tr>
                        <th>For 3 week</th>
                        <td>{vehicleData.three_week_per_day_rate ? parseFloat(vehicleData.three_week_per_day_rate).toFixed(2) : ""}/=</td>
                        <td>{vehicleData.three_week_per_total ? parseFloat(vehicleData.three_week_per_total).toFixed(2) : ""}/=</td>
                      </tr>
                      : ''
                    }

                    {vehicleData.one_month_per_day_rate || vehicleData.one_months_per_total ?
                      <tr>
                        <th>For 1 month</th>
                        <td>{vehicleData.one_month_per_day_rate ? parseFloat(vehicleData.one_month_per_day_rate).toFixed(2) : ""}/=</td>
                        <td>{vehicleData.one_months_per_total ? parseFloat(vehicleData.one_months_per_total).toFixed(2) : ""}/=
                          <br /><small>Per Month Rate</small>
                        </td>
                      </tr>
                      : ''
                    }

                    {vehicleData.three_months_per_day_rate || vehicleData.three_months_per_total ?
                      <tr>
                        <th>For 3 month</th>
                        <td>{vehicleData.three_months_per_day_rate ? parseFloat(vehicleData.three_months_per_day_rate).toFixed(2) : ""}/=</td>
                        <td>{vehicleData.three_months_per_total ? parseFloat(vehicleData.three_months_per_total).toFixed(2) : ""}/=
                          <br /><small>Per Month Rate</small>
                        </td>
                      </tr>
                      : ''
                    }

                    {vehicleData.six_months_per_day_rate || vehicleData.six_months_per_total ?
                      <tr>
                        <th>For 6 months & Over</th>
                        <td>{vehicleData.six_months_per_day_rate ? parseFloat(vehicleData.six_months_per_day_rate).toFixed(2) : ""}/=</td>
                        <td>{vehicleData.six_months_per_total ? parseFloat(vehicleData.six_months_per_total).toFixed(2) : ""}/=
                          <br /><small>Per Month Rate</small>
                        </td>
                      </tr>
                      : ''
                    }
                  </tbody>
                </table>

                <div className="discount-note mt-3 discount_blink">
                  Up to 15% off (Conditions apply)
                </div>

                <div className="discount-note mt-3">
                  Above rentals will be higher for foreigners with extra commitments.
                </div>

                <div className="discount-note mt-3 text-center">
                  <a href="tel:+94777425008">TEL - +94 777425008</a>
                </div>
                <div className="discount-note mt-3 text-center">
                  <a href="tel:+94777435008">TEL - +94 777435008</a>
                </div>

                <div className="notice">
                  <div className="discount-note mt-3">
                    <h4>Notice</h4>
                  </div>
                  <ul className="notice-list">
                    <li>➔ Standard rates are only for 100km</li>
                    <li>➔ These rates are not valid for foreigners.</li>
                    <li>
                      ➔ Above-given rates can be changed (increase or decrease)
                      from customer to customer due to high demand especially
                      in seasons, availability, features, grade, model year,
                      mileage usage, risk assessment etc.
                    </li>
                  </ul>
                  <p className="additional-note">
                    We maintain floating rates in sessional months so above rates
                    can be changed accordingly.
                  </p>
                </div>

                <div className="fleet-specifications">
                  <div className="discount-note mt-3">
                    <h4>Specifications</h4>
                  </div>
                  <ul className="specifications-list">
                    {vehicleData.ac ? vehicleData.ac == 1 ? <li>❯ Air conditioning</li> : '' : ''}
                    {vehicleData.automatic_shift ? vehicleData.automatic_shift == 1 ? <li>❯ Automatic Shift</li> : '' : ''}
                    {vehicleData.doors ? vehicleData.doors != null ? <li>❯ {vehicleData.doors} Doors</li> : '' : null}
                    {vehicleData.seats ? vehicleData.seats != null ? <li>❯ {vehicleData.seats} Seats</li> : '' : null}
                    {vehicleData.air_baloons ? vehicleData.air_baloons == 1 ? <li>❯ Air Balloons</li> : '' : ''}
                    {vehicleData.size ? vehicleData.size != null ? <li>❯ Fuel type: {vehicleData.size}</li> : '' : null}
                  </ul>
                </div>

                <div className="discount-note mt-3">
                  <h4>Related Fleets</h4>
                </div>

                <div className="related-items">
                  {relatedVehicleData.map((item) =>
                    vehicleData.id !== item.id ? <ListViewItem item={item} key={item.id} /> : null
                  )}
                </div>
              </div>
            </Col>

            <Col lg="8">
              <div className="car__info">
                <h2 className="section__title">{name}</h2>
                <p className="section__description">
                  {description}
                </p>
                <div className="d-flex align-items-center mt-3" style={{ columnGap: "4rem" }}>
                  <span className="d-flex align-items-center gap-1 section__description">
                    <i className="ri-roadster-line" style={{ color: "#D6222A" }}></i>
                    {name}
                  </span>
                  <span className="d-flex align-items-center gap-1 section__description">
                    <i className="ri-settings-2-line" style={{ color: "#D6222A" }}></i>
                    {vehicleData.discount_type == 1 ? "Automatic" : "Manual"}
                  </span>
                  <span className="d-flex align-items-center gap-1 section__description">
                    <i className="ri-timer-flash-line" style={{ color: "#D6222A" }}></i>
                    {vehicleData.wholesale_minimum_qty}kmpl
                  </span>
                </div>
              </div>

              <div className="single-item-form-section">
                <h3 className="fw-600" style={{ color: "#FFFFFF" }}>Reservation</h3>
                <form onSubmit={(e) => { e.preventDefault(); setShowConfirmation(true); }}>
                  <Row>
                    <Col>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Pick up Location"
                          name="pick_up_location"
                          value={formData.pick_up_location}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Return Location"
                          name="return_location"
                          value={formData.return_location}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="form-group">
                        <label htmlFor="pick_up_time_date" style={{ color: "#FFFFFF" }}>Pick up Date & Time</label>
                        <input
                          type="datetime-local"
                          placeholder="Pick up Date & Time"
                          name="pick_up_time_date"
                          value={formData.pick_up_time_date}
                          onChange={handleChange}
                          min={new Date().toISOString().slice(0, 16)}
                          required
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="form-group">
                        <label htmlFor="return_time_date" style={{ color: "#FFFFFF" }}>Return Date & Time</label>
                        <input
                          type="datetime-local"
                          placeholder="Return Date & Time"
                          name="return_time_date"
                          value={formData.return_time_date}
                          onChange={handleChange}
                          min={formData.pick_up_time_date || new Date().toISOString().slice(0, 16)}
                          required
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="form-group">
                        <select
                          name="person_title"
                          value={formData.person_title}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Title</option>
                          <option value="car">Mr</option>
                          <option value="van">Ms</option>
                          <option value="bus">Dr</option>
                          <option value="bus">Other</option>
                        </select>
                      </div>
                    </Col>
                    <Col>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="First Name"
                          name="first_name"
                          value={formData.first_name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Last Name"
                          name="last_name"
                          value={formData.last_name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Contact Number"
                          name="contact_number"
                          value={formData.contact_number}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="form-group">
                        <input
                          type="email"
                          placeholder="Email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="form-group">
                        <select
                          name="agent"
                          value={formData.agent}
                          onChange={handleChange}
                        >
                          <option value="">Contacted Agent (optional)</option>
                          <option value="car">Office 1</option>
                          <option value="car">Office 2</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                  <h5
                    className="total-price-display"
                    style={{
                      color: "#FF5722", // Change the text color
                      marginBottom: '20px',
                      fontWeight: 'bold',
                      border: '2px solid #FF5722', // Add a border
                      padding: '10px', // Add some padding
                      borderRadius: '8px', // Rounded corners
                      textAlign: 'center', // Center the text
                      backgroundColor: '#FFF3E0' // Light background color to make it pop
                    }}
                  >
                    Total Price: LKR {totalPrice.toFixed(2)}
                  </h5>
                  <h5 className="mt-2" style={{ color: "#FFFFFF" }}>Recommended Extra Services</h5>
                  <Row className="item_services_section">
                    {vehicleServices.map((service) => (
                      <Col
                        xs={12} // Full-width on extra-small screens
                        sm={12} // Full-width on small screens
                        md={11} // Slight padding/margin on medium screens
                        key={service.id}
                        className="item_services_section_blue_bg"
                        style={{
                          background: '#f4f4f4',
                          padding: '15px',
                          borderRadius: '10px',
                          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                          marginBottom: '10px',
                          transition: 'transform 0.2s',
                          cursor: 'pointer',
                          minHeight: '150px', // Ensure uniform card size
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                        }}
                        onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.01)')}
                        onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                      >
                        <h5 style={{ color: '#333', fontWeight: '600' }}>{service.service_name}</h5>
                        <div
                          className="d-flex justify-content-between align-items-center h-100"
                          style={{ flexWrap: 'wrap' }} // Ensures wrapping on smaller screens
                        >
                          <div
                            className="d-flex align-items-center flex-grow-1"
                            style={{ gap: '20px', flexWrap: 'wrap' }} // Ensure flexibility on mobile
                          >
                            <label className="d-flex align-items-center me-2" style={{ color: '#555' }}>
                              <input
                                type="radio"
                                name={service.service_name}
                                value="yes"
                                checked={formData.services[service.service_name] === 'yes'}
                                onChange={(e) => handleServiceChange(service.service_name, e.target.value)}
                                style={{ marginRight: '4px' }}
                              />
                              Yes
                            </label>
                            <label className="d-flex align-items-center me-2" style={{ color: '#555' }}>
                              <input
                                type="radio"
                                name={service.service_name}
                                value="no"
                                checked={formData.services[service.service_name] === 'no'}
                                onChange={(e) => handleServiceChange(service.service_name, e.target.value)}
                                style={{ marginRight: '4px' }}
                              />
                              No
                            </label>

                            {service.input_type === 'input' && (
                              <div className="d-flex flex-column align-items-center" style={{ flexBasis: '60px' }}>
                                <label className="mb-1" style={{ color: '#333', fontWeight: '500' }}>
                                  {service.service_name.toLowerCase() === 'need a driver' ? 'Days' : 'Qty'}
                                </label>
                                <input
                                  type="number"
                                  min="0"
                                  name={`${service.service_name}_quantity`}
                                  value={formData.services[`${service.service_name}_quantity`] || '0'}
                                  onChange={(e) =>
                                    setFormData((prevState) => ({
                                      ...prevState,
                                      services: {
                                        ...prevState.services,
                                        [`${service.service_name}_quantity`]: e.target.value,
                                      },
                                    }))
                                  }
                                  style={{
                                    width: '60px',
                                    padding: '5px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                  }}
                                />
                              </div>
                            )}
                          </div>

                          <span className="fw-bold ms-3" style={{ color: '#FF5722' }}>
                            LKR : {service.price} Per Day
                          </span>
                        </div>
                      </Col>
                    ))}
                  </Row>
                  <button type="submit" className="btn btn-primary single-item-form-btn" disabled={isSubmitting} style={{ width: "100%", border: "solid 2px #FFFFFF !important" }}>
                    {isSubmitting ? 'Sending...' : 'Booking'}
                  </button>
                  {isSuccess && <p>Email sent successfully!</p>}
                  {isError && <p>Failed to send email. Please try again.</p>}
                  <Modal
                    isOpen={showConfirmation}
                    toggle={() => setShowConfirmation(!showConfirmation)}
                    style={{ maxWidth: '600px' }}
                  >
                    <ModalHeader
                      toggle={() => setShowConfirmation(!showConfirmation)}
                      style={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #ddd' }}
                    >
                      Reservation Confirmation
                    </ModalHeader>
                    <ModalBody style={{ padding: '20px' }}>
                      <h5>Vehicle: {name}</h5>
                      <img
                        src={`https://admin.rentacarsrilankahbs.com/${image}`}
                        alt={name}
                        className="w-100 mb-3 rounded"
                        style={{ borderRadius: '8px' }}
                      />

                      {/* Location Fields */}
                      <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
                        <div style={{ flex: 1 }}>
                          <p style={{ marginBottom: '5px' }}>
                            <strong>Pick Up Location:</strong> {formData.pick_up_location}
                          </p>
                        </div>
                        <div style={{ flex: 1 }}>
                          <p style={{ marginBottom: '5px' }}>
                            <strong>Return Location:</strong> {formData.return_location}
                          </p>
                        </div>
                      </div>

                      {/* Date Fields */}
                      <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
                        <div style={{ flex: 1 }}>
                          <p style={{ marginBottom: '5px' }}>
                            <strong>Pick Up Date & Time:</strong>{" "}
                            {new Date(formData.pick_up_time_date).toLocaleString('en-GB', {
                              weekday: 'long',
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                            })}
                          </p>
                        </div>
                        <div style={{ flex: 1 }}>
                          <p style={{ marginBottom: '5px' }}>
                            <strong>Return Date & Time:</strong>{" "}
                            {new Date(formData.return_time_date).toLocaleString('en-GB', {
                              weekday: 'long',
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                            })}
                          </p>
                        </div>
                      </div>

                      {/* Rental Period */}
                      <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
                        <div style={{ flex: 1 }}>
                          <p style={{ marginBottom: '5px' }}>
                            <strong>Rental Period:</strong>{" "}
                            {`${Math.ceil(
                              (new Date(formData.return_time_date) -
                                new Date(formData.pick_up_time_date)) /
                              (1000 * 60 * 60 * 24)
                            )} ${Math.ceil(
                              (new Date(formData.return_time_date) -
                                new Date(formData.pick_up_time_date)) /
                              (1000 * 60 * 60 * 24)
                            ) === 1 ? 'Day' : 'Days'}`}
                          </p>
                        </div>
                      </div>

                      {/* Contact Information Fields */}
                      <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
                        <div style={{ flex: 1 }}>
                          <p style={{ marginBottom: '5px' }}>
                            <strong>Contact Name:</strong> {`${formData.first_name} ${formData.last_name}`}
                          </p>
                        </div>
                        <div style={{ flex: 1 }}>
                          <p style={{ marginBottom: '5px' }}>
                            <strong>Contact Number:</strong> {formData.contact_number}
                          </p>
                        </div>
                      </div>

                      {/* Email Field */}
                      <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
                        <div style={{ flex: 1 }}>
                          <p style={{ marginBottom: '5px' }}>
                            <strong>Email:</strong> {formData.email}
                          </p>
                        </div>
                      </div>

                      {/* Selected Services */}
                      <h5>Selected Services:</h5>
                      <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                        {vehicleServices.map(
                          (service) =>
                            formData.services[service.service_name] === 'yes' && (
                              <li
                                key={service.id}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  padding: '5px 0',
                                  borderBottom: '1px solid #eaeaea',
                                }}
                              >
                                {service.service_name} - {formData.services[`${service.service_name}_quantity`] || 1} Unit(s)
                                <span style={{ fontWeight: 'bold', color: '#ff5722' }}>
                                  (LKR {service.price * (formData.services[`${service.service_name}_quantity`] || 1)})
                                </span>
                              </li>
                            )
                        )}
                      </ul>

                      {/* Total Price */}
                      <h5
                        style={{
                          marginTop: '20px',
                          fontSize: '18px',
                          fontWeight: 'bold',
                          textAlign: 'right',
                        }}
                      >
                        Total Price: LKR {totalPrice.toFixed(2)}
                      </h5>
                    </ModalBody>
                    <ModalFooter style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                      <Button color="secondary" onClick={() => setShowConfirmation(false)}>
                        Cancel
                      </Button>
                      <Button color="primary" onClick={handleConfirmBooking} disabled={isSubmitting}>
                        {isSubmitting ? 'Confirming...' : 'Confirm Booking'}
                      </Button>
                    </ModalFooter>
                  </Modal>
                  <div className="discount-note mt-3 discount_blink_red">
                    Delivery charge will depend on your location
                  </div>
                </form>
              </div>
            </Col>

            <Col lg="7" className="mt-5">
              <div className="booking-info mt-5">
                <h5 className="mb-4 fw-bold "></h5>
              </div>
            </Col>

            <Col lg="5" className="mt-5">
              {/* Add your payment information here if needed */}
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default CarDetails;