import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import CarListing from "../pages/CarListing";
import CarDetails from "../pages/CarDetails";
import Blog from "../pages/Blog";
import BlogDetails from "../pages/BlogDetails";
import NotFound from "../pages/NotFound";
import Contact from "../pages/Contact";
import CarUpload from "../pages/CarUpload";
import CarList from "../pages/CarList";
import RentACarGuide from "../pages/RentACarGuide";
import RentACarToDambulla from "../pages/RentACarToDambulla";
import RentACarToSigiriya from "../pages/RentACarToSigiriya";
import RentACarToAnuradhapura from "../pages/RentACarToAnuradhapura";

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/cars/:slug" element={<CarDetails />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/carlist/:slug" element={<CarList />} />
      <Route path="/carlist" element={<CarList />} />
      <Route path="/rent-a-car-guide" element={<RentACarGuide />} />
      <Route path="/rent-a-car-to-dambulla" element={<RentACarToDambulla />} />
      <Route path="/rent-a-car-to-sigiriya" element={<RentACarToSigiriya />} />
      <Route path="/rent-a-car-to-anuradhapura" element={<RentACarToAnuradhapura />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Routers;
