import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col } from "reactstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "../../styles/header.css";
import Logo from "../../assets/all-images/logo/logo.png";
import GoogleTranslate from "../Layout/GoogleTranslate";

const navLinks = [
  {
    path: "/home",
    name: "Home",
  },
  {
    path: "/about",
    name: "About",
  },
  {
    path: "/contact",
    name: "Contact",
  },
  {
    path: "/carlist",
    name: "All Vehicles",
  },
];

const blogPosts = [
  { title: "The Ultimate Guide to Renting a Car", path: "/rent-a-car-guide" },
  { title: "Rent a Car to Dambulla", path: "/rent-a-car-to-dambulla" },
  { title: "Rent a Car to Sigiriya", path: "/rent-a-car-to-sigiriya" },
  { title: "Rent a Car to Anuradhapura", path: "/rent-a-car-to-anuradhapura" },
];


const Header = () => {
  const menuRef = useRef(null);
  const [categoryItem, setCategoryItem] = useState(navLinks);
  const [isVehicleDropdownOpen, setIsVehicleDropdownOpen] = useState(false);
  const [isBlogDropdownOpen, setIsBlogDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => menuRef.current.classList.toggle("menu__active");

  useEffect(() => {
    let isMounted = true;

    axios
      .get("https://admin.rentacarsrilankahbs.com/api/all/category/subcategory")
      .then((res) => {
        if (isMounted) {
          setCategoryItem((prevItems) => [...prevItems, ...res.data]);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the cars!", error);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  const closeMenuOnClick = () => {
    if (window.innerWidth <= 768) {
      menuRef.current.classList.remove("menu__active");
    }
  };

  return (
    <header className="header">
      {/* Header Top */}
      <div className="header__top">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="6">
              <div className="header__top__left fw-600">
                <span>Need Help? </span>
                <span className="header__top__help">
                  <i className="ri-phone-fill"></i>{" "}
                  <a href="tel:+94777425008" className="text-white">
                    +94 777425008
                  </a>
                </span>
              </div>
            </Col>

            <Col lg="6" md="6" sm="6">
              <div className="header__top__right d-flex align-items-center justify-content-end gap-3">
                <Link to="#" className="fw-600 d-flex align-items-center gap-1">
                  Dambulla
                </Link>
                <button className="header__btn btn ">
                  <Link to="tel:+94777425008">
                    <i className="ri-phone-line"></i> Request a call
                  </Link>
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Header Middle */}
      <div className="header__middle">
        <Container>
          <Row>
            <Col lg="3" md="3" sm="4">
              <div className="logo">
                <Link to="/home" className="d-flex align-items-center gap-2">
                  <img src={Logo} alt="" />
                </Link>
              </div>
            </Col>
            <Col lg="3" md="3" sm="4">
              <div className="header__location d-flex align-items-center gap-2">
                <span>
                  <i className="ri-roadster-fill"></i>
                </span>
                <div className="header__location-content">
                  <h4>More Than 50 Vehicles</h4>
                  <h6>24 Hours a Day</h6>
                </div>
              </div>
            </Col>
            <Col lg="6" md="6" sm="4" style={{ textAlign: "end" }}>
              <GoogleTranslate />
            </Col>
          </Row>
        </Container>
      </div>

      {/* Main Navigation */}
      <div className="main__navbar">
        <Container>
          <div className="navigation__wrapper d-flex align-items-center justify-content-between">
            <span className="mobile__menu">
              <i className="ri-menu-line" onClick={toggleMenu}></i>
            </span>

            <div className="navigation" ref={menuRef}>
              <div className="menu">
                {categoryItem.map((item, index) => (
                  item.name === "Home" && (
                    <NavLink
                      to={item.path}
                      className={(navClass) =>
                        navClass.isActive ? "nav__active nav__item fw-600" : "nav__item fw-600"
                      }
                      key={index}
                      onClick={closeMenuOnClick}
                    >
                      {item.name}
                    </NavLink>
                  )
                ))}

                {/* Vehicle & Rents Dropdown */}
                <div
                  className={`nav__item fw-600 dropdown ${isVehicleDropdownOpen ? "open" : ""}`}
                  onMouseEnter={() => setIsVehicleDropdownOpen(true)}
                  onMouseLeave={() => setIsVehicleDropdownOpen(false)}
                >
                  <span className="nav__link dropdown-toggle" role="button">
                    Vehicle & Rents
                  </span>
                  <div className={`dropdown-menu ${isVehicleDropdownOpen ? "show" : ""}`}>
                    {categoryItem.map((item, index) => (
                      item.name !== "Home" &&
                      item.name !== "About" &&
                      item.name !== "Blog" &&
                      item.name !== "Contact" && (
                        <NavLink
                          to={`/carlist/${item.id}`}
                          className="dropdown-item"
                          key={index}
                          onClick={closeMenuOnClick}
                        >
                          {item.name}
                        </NavLink>
                      )
                    ))}
                  </div>
                </div>

                {/* Blog Dropdown */}
                <div
                  className={`nav__item fw-600 dropdown ${isBlogDropdownOpen ? "open" : ""}`}
                  onMouseEnter={() => setIsBlogDropdownOpen(true)}
                  onMouseLeave={() => setIsBlogDropdownOpen(false)}
                >
                  <span className="nav__link dropdown-toggle" role="button">
                    Blog
                  </span>
                  <div className={`dropdown-menu ${isBlogDropdownOpen ? "show" : ""}`}>
                    {blogPosts.map((post, index) => (
                      <Link
                        to={post.path}
                        className="dropdown-item"
                        key={index}
                        onClick={closeMenuOnClick}
                      >
                        {post.title}
                      </Link>
                    ))}
                  </div>
                </div>

                {/* Other Links */}
                {categoryItem.map((item, index) => (
                  (item.name === "About" || item.name === "Contact") && (
                    <NavLink
                      to={item.path}
                      className={(navClass) =>
                        navClass.isActive ? "nav__active nav__item fw-600" : "nav__item fw-600"
                      }
                      key={index}
                      onClick={closeMenuOnClick}
                    >
                      {item.name}
                    </NavLink>
                  )
                ))}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </header>
  );
};

export default Header;