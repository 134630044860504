import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../../styles/about-section.css";
import aboutImg from "../../assets/all-images/cars-img/bmw-offer.png";

const AboutSection = ({ aboutClass }) => {
  return (
    <section
      className="about__section"
      style={
        aboutClass === "aboutPage"
          ? { marginTop: "0px" }
          : { marginTop: "0px" }
      }
    >
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="about__section-content">
              <h4 className="section__subtitle">About Us</h4>
              <h2 className="section__title"> Welcome to HBS Rent A Car</h2>
              <p className="section__description">
              Your trusted partner in travel and transportation. Founded on the principles of exceptional customer service and unbeatable convenience, HBS Rent A Car has been a cornerstone in the car rental industry, committed to making every journey seamless and enjoyable.
              </p>

              <h2 className="section__title">Our Story</h2>
              <p className="section__description">
              HBS Rent A Car began with a simple vision: to revolutionize the car rental experience by putting our customers first. From our humble beginnings, we have grown into a renowned name, known for our diverse fleet, competitive pricing, and unwavering dedication to quality.
              </p>
            </div>
          </Col>

          <Col lg="6" md="6">
            <div className="about__img">
              <img src={aboutImg} alt="" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutSection;
