import React from "react";
import { Link } from "react-router-dom";
import "../styles/not-found.css";
const NotFound = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <h1 className="not-found-title">404</h1>
        <p className="not-found-message">Oops! The page you are looking for does not exist.</p>
        <p className="not-found-description">
          It seems you may have taken a wrong turn. Don’t worry, it happens to the best of us!
        </p>
        <Link to="/home" className="not-found-button">
          Go Back to Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;