import React from "react";

import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import AboutSection from "../components/UI/AboutSection";
import { Container, Row, Col } from "reactstrap";
import BecomeDriverSection from "../components/UI/BecomeDriverSection";

import driveImg from "../assets/all-images/drive.jpg";
import OurMembers from "../components/UI/OurMembers";
import "../styles/about.css";

const About = () => {
  return (
    <Helmet title="About">
      <CommonSection title="About Us" />
      <AboutSection aboutClass="aboutPage" />

      <section className="about__page-section">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="about__page-img">
                <img src={driveImg} alt="" className="w-100 rounded-3" />
              </div>
            </Col>

            <Col lg="6" md="6" sm="12">
              <div className="about__page-content">
                <h2 className="section__title">
                  Why Choose Us?
                </h2>

                <p className="section__description">
                  <h6>⁠Wide Range of Vehicles:-</h6>From compact cars to luxury sedans, SUVs to vans, our extensive fleet ensures that you find the perfect vehicle to suit your needs. 
                  <h6>⁠Customer-Centric Approach:-</h6>We prioritize your comfort and convenience, offering flexible rental terms, easy booking processes, and 24/7 customer support.
                  <h6>⁠Competitive Pricing:-</h6> We offer transparent pricing with no hidden fees, ensuring you get the best value for your money.
                  <h6>Quality and Safety:-</h6> All our vehicles are regularly maintained and thoroughly inspected to guarantee your safety and peace of mind.
                </p>
                <div className=" d-flex align-items-center gap-0 mt-0">
                  <span className="fs-4">
                    <i class="ri-phone-line"></i>
                  </span>

                  <div>
                    <h6 className="section__subtitle">Need Any Help?</h6>
                    <h4>+94707435008</h4>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <BecomeDriverSection />
      {/* 
      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h6 className="section__subtitle">Experts</h6>
              <h2 className="section__title">Our Members</h2>
            </Col>
            <OurMembers />
          </Row>
        </Container>
      </section> */}
    </Helmet>
  );
};

export default About;
